import React, { Component } from 'react';
import { auth } from '../../firebase';

// Sass
import './Login.scss';

// Components
import Button from '../../components/button';
import PasswordInput from '../../components/passwordInput/PasswordInput.jsx';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.passwordCB = this.passwordCB.bind(this);
  }

  handleChange(event) {
    if (event.target.className === 'login__email') {
      this.setState({ email: event.target.value });
    } else if (event.target.className === 'login__password') {
      this.setState({ password: event.target.value });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    const email = this.state.email;
    const pass = this.state.password;
    auth
      .signInWithEmailAndPassword(email, pass)
      .then((cred) => {
        this.props.history.push('/');
      })
      .catch((error) => {
        console.log('hér');
        this.setState({ loginErr: true });
      });
  }

  passwordCB(who, value) {
    this.setState({ password: value });
  }

  componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.props.history.push('/');
      } else {
      }
    });
  }

  render() {
    const { loginErr } = this.state;
    let villa;
    if (loginErr) {
      villa = (
        <p className='login__error incorrect' data-testid='error'>
          Netfang og/eða lykilorð er ekki rétt slegið inn
        </p>
      );
    }

    return (
      <div
        className='auth col col-4 offset-col-4 col-md-8 offset-col-md-2'
        data-testid='login'>
        <h2>Innskráning</h2>
        <div className='login'>
          <form>
            <label htmlFor='email'>Netfang</label>
            <input
              id='email'
              className='login__email'
              type='email'
              onChange={this.handleChange}
              data-testid='login__email'></input>
            <PasswordInput
              text='Lykilorð'
              htmlFor='lykilord'
              passwordCB={this.passwordCB}
              placeholder='Lykilorð'
            />
            <Button
              className='login__button'
              onClick={this.handleSubmit}
              children='Skrá inn'
              dataTestid='login__submit'></Button>
          </form>
        </div>
        {villa}
      </div>
    );
  }
}

export default Login;
