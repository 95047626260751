import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Routes
import Login from './routes/login/Login';
import Start from './routes/start/Start';
import Skra from './routes/skra/Skra';
import Results from './routes/results/Results';
import ChangePassword from './routes/changePassword/ChangePassword.jsx';
import Info from './routes/info/Info';

// Components
import Header from './components/header/Header.jsx';

// CSS
import './App.scss';
import './grid.scss';
//import "./grid-overlay.scss";

class App extends Component {
  loginCallback(childData) {
    this.setState({ loggedIn: childData });
  }

  render() {
    return (
      <Router>
        <Header />
        <main className='App' onClick={this.handleClick} data-testid='App'>
          <div className='grid'>
            <Switch location={this.props.location}>
              <Route path='/' exact component={Start} />
              <Route path='/login' exact component={Login} />
              <Route path='/changepassword' exact component={ChangePassword} />
              <Route path='/skra' exact component={Skra} />
              <Route path='/results' exact component={Results} />
              <Route path='/info' exact component={Info} />
            </Switch>
          </div>
        </main>
      </Router>
    );
  }
}

export default App;
