import React, { Component } from 'react';
import { auth, firestore } from '../../firebase';

// Components
import ResultsTafla from '../../components/resultsTafla/ResultsTafla';
import ResultsMedal from '../../components/resultsMedal/ResultsMedal';
import ResultsNemenda from '../../components/resultsNemenda/ResultsNemenda';

// Assets
import { sanitizeAndValidateGogn } from '../../assets/js/functions';

// CSS
import './Results.scss';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: parseInt(localStorage.getItem('fjoldiIProfi')),
      doneNem: parseInt(localStorage.getItem('doneNem')),
      uid: '',
    };

    this.sendAll = this.sendAll.bind(this);
    this.addToFirebase = this.addToFirebase.bind(this);
    this.updateFirebase = this.updateFirebase.bind(this);
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ loggedIn: true });
        this.sendAll(user.uid);
      } else {
        this.props.history.push('/login');
      }
    });
  }

  // Sækir gögn í LocalStorage og sendir inn á Firebase.
  // Setur gildi á success eða error eftir því hvort að
  // gagnasending tókst eða ekki.
  async sendAll(uid) {
    const { fjoldi } = this.state;
    for (let i = 0; i < fjoldi; i++) {
      const lsGogn = localStorage.getItem('nemandi' + (i + 1)).split(',');
      const gogn = sanitizeAndValidateGogn(lsGogn, uid);
      if (lsGogn[17] === 'needsSending') {
        if (gogn) {
          await this.addToFirebase(lsGogn, gogn, i);
        }
      } else if (lsGogn[17] === 'needsUpdating') {
        if (gogn) {
          await this.updateFirebase(lsGogn, gogn, i, lsGogn[18]);
        }
      }
    }
  }

  async addToFirebase(lsGogn, gogn, i) {
    await firestore
      .collection('hljom2__skra')
      .add(gogn)
      .then((res) => {
        console.log('Sendi inn númer: ' + (i + 1));

        lsGogn[17] = 'sent';
        lsGogn[18] = res.id;
        localStorage.setItem('nemandi' + (i + 1), lsGogn);
      })
      .catch((err) => {
        console.error('Error writing document: ', err);
        this.setState({ error: true });
      });
  }

  async updateFirebase(lsGogn, gogn, i, uid) {
    await firestore
      .collection('hljom2__skra')
      .doc(uid)
      .update(gogn)
      .then((res) => {
        lsGogn[17] = 'sent';
        lsGogn[18] = uid;
        localStorage.setItem('nemandi' + (i + 1), lsGogn);
      });
  }

  render() {
    const { success, error } = this.state;

    let successMessage;
    if (success) {
      successMessage = (
        <p className='correct message'>
          Sending á niðurstöðum tókst, takk fyrir.
        </p>
      );
    }

    let errorMessage;
    if (error) {
      errorMessage = (
        <p className='incorrect message'>
          Ekki tókst að senda niðurstöður inn til Menntámálastonunar.
          Vinsamlegast reynið aftur.
        </p>
      );
    }

    return (
      <div className='results row'>
        <h2 className='col col-6 offset-col-3 col-md-8 offset-col-md-2'>
          Niðurstöður úr HLJÓM-2 athugun
        </h2>
        {successMessage}
        {errorMessage}
        <ResultsTafla></ResultsTafla>
        <ResultsMedal aldursbil='1'></ResultsMedal>
        <ResultsMedal aldursbil='2'></ResultsMedal>
        <ResultsMedal aldursbil='3'></ResultsMedal>
        <ResultsMedal aldursbil='4'></ResultsMedal>
        <ResultsNemenda></ResultsNemenda>
      </div>
    );
  }
}
export default Results;
