import React, { useState, useEffect } from 'react';

// Sass
import './FyrriSeinni.scss';

// Components
import Button from '../button/Button';
import DatePicker from 'react-date-picker';

// Assets
import { calcAldursbil, lita } from '../../assets/js/functions';
import close from '../../assets/img/close.svg';

export default function FyrriSeinni(props) {
  const { children, fyrriSeinniCB, who, kennitala } = props;
  const [activeSub, setActiveSub] = useState('button__inactive');
  const [vis, setVis] = useState(false);
  const [profdagur, setProfdagur] = useState(new Date());
  const [aldursbil, setAldursbil] = useState(false);
  const [rim, setRim] = useState(false);
  const [samstofur, setSamstofur] = useState(false);
  const [samsett, setSamsett] = useState(false);
  const [hljodgreining, setHljodgreining] = useState(false);
  const [margraed, setMargraed] = useState(false);
  const [ordhlutaeyding, setOrdhlutaeyding] = useState(false);
  const [hljodtenging, setHljodtenging] = useState(false);
  const [litur1, setLitur1] = useState('');
  const [litur2, setLitur2] = useState('');
  const [litur3, setLitur3] = useState('');
  const [litur4, setLitur4] = useState('');
  const [litur5, setLitur5] = useState('');
  const [litur6, setLitur6] = useState('');
  const [litur7, setLitur7] = useState('');
  const [aldursbilMessage, setAldursbilMessage] = useState(null);
  const [aldursbilClass, setAldursbilClass] = useState(null);

  useEffect(() => {
    const aldursbilInfo = calcAldursbil(
      kennitala,
      profdagur.toISOString().split('T')[0].split('-'),
    );
    setAldursbil(aldursbilInfo.aldursbil);
    setAldursbilMessage(
      `Aldursbil ${aldursbilInfo.aldursbil} (${aldursbilInfo.aldurDagar} daga gamall)`,
    );
    setAldursbilClass('correct');
  }, [kennitala, profdagur]);

  const handleProfdagur = (e) => {
    if (e <= new Date()) {
      setProfdagur(e);
      const aldursbilInfo = calcAldursbil(
        kennitala,
        e.toISOString().split('T')[0].split('-'),
      );
      setAldursbil(aldursbilInfo.aldursbil);
      setAldursbilMessage(
        `Aldursbil ${aldursbilInfo.aldursbil} (${aldursbilInfo.aldurDagar} dagar)`,
      );
      setAldursbilClass('correct');
    } else {
      setAldursbilMessage('Dagsetning fram í tímann');
      setAldursbilClass('incorrect');
    }
  };

  // Setur CSS litaklasa á öll dropdown sem búið var að fylla inn
  // áður en að kennitala og prófdagsetning voru komin. Ljótt fall
  // endurtekning á kóða, laga við tækifæri.
  // Tekur  : Tölu (1-4) sem samsvarar aldursbili nemanda.
  // Skilar : Engu.
  const colorAll = (aldursbil) => {
    if (rim) {
      const litur = lita(1, parseInt(aldursbil), rim);
      setLitur1(litur);
    }
    if (samstofur) {
      const litur = lita(2, parseInt(aldursbil), samstofur);
      setLitur2(litur);
    }
    if (samsett) {
      const litur = lita(3, parseInt(aldursbil), samsett);
      setLitur3(litur);
    }
    if (hljodgreining) {
      const litur = lita(4, parseInt(aldursbil), hljodgreining);
      setLitur4(litur);
    }
    if (margraed) {
      const litur = lita(5, parseInt(aldursbil), margraed);
      setLitur5(litur);
    }
    if (ordhlutaeyding) {
      const litur = lita(6, parseInt(aldursbil), ordhlutaeyding);
      setLitur6(litur);
    }
    if (hljodtenging) {
      const litur = lita(7, parseInt(aldursbil), hljodtenging);
      setLitur7(litur);
    }
  };

  // Athugar hvort að það hafi verið fyllt út í allt í forminu og
  // breytir þá "Reikna niðurstöður yfir í active"
  // Tekur  : Ekkert.
  // Skilar : Engu.
  const activateSubmit = () => {
    if (
      aldursbil &&
      rim &&
      samstofur &&
      samsett &&
      hljodgreining &&
      margraed &&
      ordhlutaeyding &&
      hljodtenging
    ) {
      setActiveSub('');
    }
  };

  useEffect(() => {
    colorAll(aldursbil);
    activateSubmit();
    //eslint-disable-next-line
  }, [
    aldursbil,
    rim,
    samstofur,
    samsett,
    hljodgreining,
    margraed,
    ordhlutaeyding,
    hljodtenging,
    activateSubmit,
  ]);

  const closeAndRemove = () => {
    setVis(false);
    fyrriSeinniCB(who, false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeSub !== 'button__inactive') {
      const sum =
        parseInt(rim) +
        parseInt(samsett) +
        parseInt(samstofur) +
        parseInt(margraed) +
        parseInt(hljodgreining) +
        parseInt(ordhlutaeyding) +
        parseInt(hljodtenging);
      const liturTotal = lita('heild', parseInt(aldursbil), sum);
      fyrriSeinniCB(who, [
        parseInt(rim),
        parseInt(samstofur),
        parseInt(samsett),
        parseInt(hljodgreining),
        parseInt(margraed),
        parseInt(ordhlutaeyding),
        parseInt(hljodtenging),
        sum,
        aldursbil,
        litur1,
        litur2,
        litur3,
        litur4,
        litur5,
        litur6,
        litur7,
        liturTotal,
      ]);
      setVis(false);
    }
  };

  if (!vis) {
    return (
      <Button
        className='initialButton'
        children={children}
        onClick={() => {
          setVis(true);
        }}
      />
    );
  } else {
    return (
      <>
        <img
          src={close}
          alt='Loka'
          className='closeFyrriSeinni'
          onClick={closeAndRemove}
        />
        <div className='fyrriSeinni'>
          <h3>
            {who.charAt(0).toUpperCase()}
            {who.slice(1, 6)} athugun
          </h3>
          <form>
            <div className='dropDown'>
              <label>Prófdagur</label>
              <p className={aldursbilClass}>{aldursbilMessage}</p>
              <div>
                <DatePicker
                  value={profdagur}
                  onChange={(e) => {
                    handleProfdagur(e);
                  }}
                  format='dd-MM-y'
                  asp-format='{0:yyyy-MM-dd}'
                />
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='1'>Rím</label>
              <div>
                <select
                  id='1'
                  onChange={(e) => {
                    setRim(e.target.value);
                  }}
                  value={rim}
                  className={litur1}>
                  <option>-</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                </select>
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='2'>Samstöfur</label>
              <div>
                <select
                  id='2'
                  onChange={(e) => {
                    setSamstofur(e.target.value);
                  }}
                  value={samstofur}
                  className={litur2}>
                  <option>-</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='3'>Samsett orð</label>
              <div>
                <select
                  id='3'
                  onChange={(e) => {
                    setSamsett(e.target.value);
                  }}
                  value={samsett}
                  className={litur3}>
                  <option>-</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='4'>Hljóðgreining</label>
              <div>
                <select
                  id='4'
                  onChange={(e) => {
                    setHljodgreining(e.target.value);
                  }}
                  value={hljodgreining}
                  className={litur4}>
                  <option>-</option>
                  <option>0</option>
                  <option>3</option>
                  <option>6</option>
                  <option>9</option>
                  <option>12</option>
                  <option>15</option>
                </select>
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='5'>Margræð orð</label>
              <div>
                <select
                  id='5'
                  onChange={(e) => {
                    setMargraed(e.target.value);
                  }}
                  value={margraed}
                  className={litur5}>
                  <option>-</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='6'>Orðhlutaeyðing</label>
              <div>
                <select
                  id='6'
                  onChange={(e) => {
                    setOrdhlutaeyding(e.target.value);
                  }}
                  value={ordhlutaeyding}
                  className={litur6}>
                  <option>-</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                </select>
              </div>
            </div>
            <div className='dropDown'>
              <label htmlFor='7'>Hljóðtenging</label>
              <div>
                <select
                  id='7'
                  onChange={(e) => {
                    setHljodtenging(e.target.value);
                  }}
                  value={hljodtenging}
                  className={litur7}>
                  <option>-</option>
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                </select>
              </div>
            </div>
            <Button
              children='Bæta gögnum við nemanda'
              onClick={handleSubmit}
              className={activeSub}></Button>
          </form>
        </div>
      </>
    );
  }
}
