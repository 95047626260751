import React, { useState, useEffect } from 'react';
import { Radar } from 'react-chartjs-2';
//import 'chartjs-plugin-labels';

// Sass
import './ResultEinstaklingsRadar.scss';

// Assets
import infoIcon from '../../assets/img/information.svg';

// Global fastar
const msf = [
  [2, 3, 0, 0, 3, 0, 0],
  [4, 4, 1, 0, 3, 1, 0],
  [5, 4, 2, 0, 4, 1, 1],
  [6, 5, 3, 0, 4, 1, 1],
];
const sf = [
  [5, 5, 2, 0, 4, 2, 1],
  [6, 5, 4, 0, 5, 3, 1],
  [8, 6, 5, 0, 5, 4, 2],
  [9, 7, 6, 0, 6, 4, 2],
];
const mf = [
  [10, 8, 7, 3, 7, 7, 4],
  [11, 8, 8, 6, 7, 8, 5],
  [12, 8, 9, 9, 8, 9, 6],
  [12, 8, 9, 12, 8, 9, 7],
];
const gf = [
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
  [12, 8, 10, 15, 8, 10, 8],
];

export default function ResultEinstaklingsRadar(props) {
  const { nemNum, fyrri, seinni } = props;
  const nemandi = localStorage.getItem(`nemandi${nemNum}`)?.split(',');
  const aldursbil = parseInt(nemandi[7]);

  const [data, setData] = useState({
    labels: ['a'],
    datasets: [
      {
        label: '25% nemenda',
        data: [12],
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
    ],
  });
  const [options] = useState({
    title: {
      display: false,
    },
    scale: {
      angleLines: {
        display: false,
      },
      ticks: {
        suggestedMin: -3,
        suggestedMax: 16,
      },
    },
    legend: {
      display: true,
      position: 'right',
      labels: {
        fontColor: '#000',
      },
    },
  });
  const [radarHover, setRadarHover] = useState(false);
  const [fyrriData, setFyrriData] = useState(null);
  const [seinniData, setSeinniData] = useState(null);

  useEffect(() => {
    setRadarData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (fyrri) {
      const fyrriA = fyrri.slice(0, 7);
      setFyrriData(fyrriA);
    }

    if (seinni) {
      const seinniA = seinni.slice(0, 7);
      setSeinniData(seinniA);
    }

    setRadarData();
    //eslint-disable-next-line
  }, [fyrri, seinni]);

  useEffect(() => {
    setRadarData();
    // eslint-disable-next-line
  }, [fyrriData, seinniData]);

  const setRadarData = () => {
    const radarData = {
      labels: [
        'Rím',
        'Samstöfur',
        'Samsett orð',
        'Hljóðgreining',
        'Margræð orð',
        'Orðhlutaeyðing',
        'Hljóðtenging',
      ],
      datasets: [
        {
          label: 'Frammistaða nemanda',
          data: [
            nemandi[8],
            nemandi[9],
            nemandi[10],
            nemandi[11],
            nemandi[12],
            nemandi[13],
            nemandi[14],
          ],
          backgroundColor: 'rgb(243, 243, 23)',
          borderWidth: 1,
          borderColor: 'rgb(243, 243, 23)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: 'none',
        },
        {
          label: 'Mjög slök færni',
          data: msf[aldursbil - 1],
          backgroundColor: 'rgba(241, 74, 74, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(241, 74, 74)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
        },
        {
          label: 'Slök færni',
          data: sf[aldursbil - 1],
          backgroundColor: 'rgba(163, 159, 159, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(163, 159, 159)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
        {
          label: 'Meðalfærni',
          data: mf[aldursbil - 1],
          backgroundColor: 'rgba(63, 151, 63, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(63, 151, 63)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
        {
          label: 'Góð færni',
          data: gf[aldursbil - 1],
          backgroundColor: 'rgba(97, 97, 221, 0.5)',
          borderWidth: 1,
          borderColor: 'rgb(97, 97, 221)',
          hoverBorderWidth: 3,
          hoverBorderColor: '#000',
          fill: '-1',
        },
      ],
    };

    if (fyrriData) {
      radarData.datasets.unshift({
        label: 'Fyrri frammistaða nemanda',
        data: fyrriData,
        backgroundColor: 'rgb(240, 150, 0)',
        borderWidth: 1,
        borderColor: 'rgb(240, 150, 0)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: 'none',
      });
    }

    if (seinniData) {
      radarData.datasets.unshift({
        label: 'Seinni frammistaða namenda',
        data: seinniData,
        backgroundColor: 'rgb(240, 150, 0)',
        borderWidth: 1,
        borderColor: 'rgb(240, 150, 0)',
        hoverBorderWidth: 3,
        hoverBorderColor: '#000',
        fill: 'none',
      });
    }

    setData(radarData);
  };

  const radarMouseOver = () => {
    setRadarHover(true);
  };

  const radarMouseLeave = () => {
    setRadarHover(false);
  };

  let radarClass;
  if (radarHover) {
    radarClass = 'hoverOver';
  }

  return (
    <div>
      <div className='chartdiv'>
        <img
          onMouseOver={radarMouseOver}
          onMouseLeave={radarMouseLeave}
          src={infoIcon}
          alt='Upplýsingatákn'></img>
        <div className='hoverbox'>
          <div className={'hoverbox__bottom radar' + nemNum}>
            <Radar data={data} options={options} />
          </div>
          <div className={'hoverbox__top ' + radarClass}>
            <p>
              Litirnir rauður, grár, grænn og blár sýna svæðin sem afmarka
              viðeigandi færniviðmið. Guli ferillinn sýnir frammistöðu þessa
              einstaka nemanda.
            </p>
            <p>
              Hér má sjá hvort að nemandinn sé slakur í einhverjum þætti
              prófsins og hvort að hann þurfi sérstakt aðhald til að ná betri
              tökum á viðkomandi þætti.
            </p>
            <p>
              Ef einn eða fleiri punktar eru farnir að nálgast miðju, það er,
              komnir inn á rauða, gráa eða neðarlega á græna svæðið þá ætti að
              huga að aðgerðum.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
