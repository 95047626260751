import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../firebase';
import firebase from 'firebase/app';

// Components
import Button from '../../components/button';
import PasswordInput from '../../components/passwordInput/PasswordInput.jsx';

// Sass
import './ChangePassword.scss';

export default function ChangePassword() {
  const [email, setEmail] = useState('');
  const [oldPW, setOldPW] = useState('');
  const [newPW, setNewPW] = useState('');
  const [newPWAgaing, setNewPWAgaing] = useState('');
  const [errorCode, setErrorCode] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  let history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        history.push('/login');
      }
    });
  }, [history]);

  const changePassword = (e) => {
    e.preventDefault();

    firebase
      .auth()
      .signInWithEmailAndPassword(email, oldPW)
      .then((userCredential) => {
        if (newPW === newPWAgaing) {
          const user = firebase.auth().currentUser;
          user
            .updatePassword(newPW)
            .then(() => {
              window.alert(
                'Lykilorði hefur nú verið breytt. Aðgangurinn þinn verður skráður út og þú getur nú skráð þig inn afur með nýju lykilorði.',
              );
              firebase
                .auth()
                .signOut()
                .then(() => {
                  history.push('/');
                })
                .catch((err) => {
                  console.error(err);
                });
            })
            .catch((err) => {
              setErrorCode(err.code);
            });
        } else {
          setErrorCode('no match');
        }
      })
      .catch((err) => {
        console.log(err.code);
        setErrorCode(err.code);
      });
  };

  if (errorCode) {
    if (errorCode === 'auth/weak-password' && !errorMessage) {
      setErrorMessage('Lykilorð verður að vera í það minnsta 6 stafa langt.');
    } else if (errorCode === 'no match' && !errorMessage) {
      setErrorMessage(
        '"Nýtt lykilorð" og "Nýtt lykilorð aftur" eru ekki eins.',
      );
    } else if (
      (errorCode === 'auth/wrong-password' ||
        errorCode === 'auth/invalid-email') &&
      !errorMessage
    ) {
      setErrorMessage('Ekki rétt netfang eða lykilorð.');
    } else if (!errorMessage) {
      setErrorMessage('Villa kom upp, vinsamlegast reynið aftur');
    }
  }

  const passwordCB = (who, value) => {
    if (who === 'nuverandiLykilord') {
      setOldPW(value);
    } else if (who === 'nyttLykilord') {
      setNewPW(value);
    } else if (who === 'nyttLykilordAftur') {
      setNewPWAgaing(value);
    }
  };

  return (
    <div className='changePassword col col-4 offset-col-4 col-md-8 offset-col-md-2'>
      <h2>Breyta lykilorði</h2>
      <p className='incorrect'>{errorMessage}</p>
      <form>
        <label htmlFor='email'>Netfang</label>
        <input
          id='email'
          type='email'
          placeholder='Netfang'
          onChange={(e) => {
            setEmail(e.target.value);
          }}></input>
        <PasswordInput
          text='Núverandi lykilorð'
          htmlFor='nuverandiLykilord'
          passwordCB={passwordCB}
          placeholder='Núverandi lykilorð'
        />
        <PasswordInput
          text='Nýtt lykilorð'
          htmlFor='nyttLykilord'
          passwordCB={passwordCB}
          placeholder='Nýtt lykilorð'
        />
        <PasswordInput
          text='Nýtt lykilorð aftur'
          htmlFor='nyttLykilordAftur'
          passwordCB={passwordCB}
          placeholder='Nýtt lykilorð aftur'
        />
        <Button children='Breyta' onClick={changePassword} />
      </form>
    </div>
  );
}
