import React, { useEffect } from 'react';
import { auth } from '../../firebase';
import { useHistory } from 'react-router-dom';

// Sass
import './Info.scss';

// Components
import Button from '../../components/button/Button';

// Assets
import handbok from '../../assets/pdf/handbok.pdf';
import myndir from '../../assets/pdf/myndir.pdf';
import eydublad from '../../assets/pdf/eydublad.pdf';
import thjalfun from '../../assets/pdf/thjalfun.pdf';
import foreldrar from '../../assets/pdf/foreldrar.pdf';
import hljom2namskeid from '../../assets/pdf/hljom2namskeid.zip';
import aIslensku from '../../assets/pdf/utsk_hljom_2_isl.pdf';
import aEnsku from '../../assets/pdf/utsk_hljom_2_ensk.pdf';
import aPolsku from '../../assets/pdf/utsk_hljom_2_polsk.pdf';
import inngangur from '../../assets/pdf/hljom_inng_2020.pdf';
import adgerdaraaetlun from '../../assets/pdf/hljom_adgerdaraaetlun_2020.pdf';
import rim from '../../assets/pdf/hljom_rim_2020.pdf';
import samstofur from '../../assets/pdf/hljom_samstofur_2020.pdf';
import samsett from '../../assets/pdf/hljom_samsett_2020.pdf';
import hljodgreining from '../../assets/pdf/hljom_hljodgreining_2020.pdf';
import margraed from '../../assets/pdf/hljom_margraed_2020.pdf';
import ordhlutaeyding from '../../assets/pdf/hljom_ordhlutaeyding_2020.pdf';
import hljodtenging from '../../assets/pdf/hljom_hljodtenging_2020.pdf';

export default function Info() {
  let history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (!user) {
        history.push('/login');
      }
    });
  }, [history]);

  return (
    <div className='info col col-8 offset-col-2 col-md-10 offset-col-md-1'>
      <h2>Hvernig nota á innsláttargáttina?</h2>
      <p>
        Best er að byrja á því að taka prófið með nemendum. Þegar kennari hefur
        stafla af útfylltum prófum er best að byrja að skrá inn niðurstöður.
      </p>
      <p>
        Innsláttargáttin byrjar að biðja um nafn, kennitölu, leikskóla og
        sveitarfélag kennara og því næst hversu margir nemendur hafa þreytt
        prófið. Bæði fjölda og upplýsingum sem snúa að kennara má breyta síðar
        með því að klikka á menu táknið á stikunni efst á síðunni. úr topp
        stikunni má einnig henda upplýsingum sem hafa verið skráðar inn, breyta
        lykilorði og skrá notanda út.
      </p>
      <p>
        Þegar form hefur verið fyllt út fyrir nemanda er ýtt á takka neðst í
        forminu sem reiknar heildarniðurstöðu fyrir þann nemanda. Þegar
        heildarniðurstaða hefur verið reiknuð út má enn breyta til í forminu en
        eftir breytingar þarf að smella aftur á hnappinn sem reiknar
        niðurstöðurnar.
      </p>
      <p>
        Þegar öll form hafa verið fyllt út er smellt á hnapp neðst á síðu sem
        tekur notanda yfir á niðurstöðusíðu. Efst á síðunni má sjá töflu yfir
        niðurstöður allra nemenda og boðið er upp á að vista niðurstöðurnar á
        .xlsx skráarsniði (excel) eða .pdf skráarsniði. Næstu möguleikar eru
        meðalframmistaða hópsins á hverju aldursbili fyrir sig. Ef athugunin
        inniheldur enga nemendur af tilteknu aldursbili, þá birtist ekki
        möguleiki fyrir það.
      </p>
      <p>
        Neðst á niðurstöðusíðu má svo skoða niðurstöður einstaklinga. Hægt er að
        sækja niðurstöður allra á .pdf skráarsniði með smell á takka efst. Ef
        öllum skjölum er hlaðið niður í einu mun vafrinn líklega biðja um
        samþykki fyrir því að gáttin hlaði niður mörgum skjölum og mikilvægt er
        að samþykkja það. Einnig er hægt að opna stakann nemanda og vista stakt
        skjal.
      </p>
      <p>
        Ef nemandi hefur farið áður í athugun má slá inn niðurstöður úr þeirri
        athugun á einstaklings síðunni. Þessar upplýsingar fylgja með þegar
        niðurstöður eru sóttar og vistaðar.
      </p>
      <p>
        Athugið að innsláttargáttin er eingöngu til innsláttar og notendur hafa
        ekki aðgang að gögnum í henni ef þeir skrá sig út. Gögn vistast í
        gagnagrunn hjá Menntamálastofnun og er hægt að óska eftir fyrri
        niðurstöðum ef nauðsyn er til.
      </p>
      <div className='videoDiv'>
        <iframe
          width='560'
          height='315'
          src='https://www.youtube.com/embed/wOYhvHPwf8U'
          title='YouTube video player'
          frameBorder='0'
          allow='accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        />
      </div>
      <h2>Upplýsingar um HLJÓM-2 skimunarprófið</h2>
      <p>
        HLJÓM-2 er skimunarpróf sem var gefið út árið 2002 en vinna við það
        hófst árið 1996. Prófið er unnið af Ingibjörgu Símonardóttur, Jóhönnu
        Einarsdóttur og Amalíu Björnsdóttur. Verkefninu fylgir handbók sem má
        nálgast hér auk fylgigagna.
      </p>
      <h3>Helstu fylgigögn</h3>
      <div className='downloadDiv'>
        <a href={handbok} target='_blank' rel='noopener noreferrer'>
          <Button children='Handbók' />
        </a>
        <a href={myndir} target='_blank' rel='noopener noreferrer'>
          <Button children='Myndir með prófi' />
        </a>
        <a href={eydublad} target='_blank' rel='noopener noreferrer'>
          <Button children='Prófblað' />
        </a>
        <a href={foreldrar} target='_blank' rel='noopener noreferrer'>
          <Button children='Foreldrabréf' />
        </a>
        <a href={thjalfun} target='_blank' rel='noopener noreferrer'>
          <Button children='Þjálfunarleiðbeiningar' />
        </a>
        <a href={hljom2namskeid} target='_blank' rel='noopener noreferrer'>
          <Button children='HlJÓM-2 námskeið (.zip)' />
        </a>
      </div>
      <h3>Kynningarbréf</h3>
      <p>
        HLJÓM-2 er aldursbundin skimun sem er lögð fyrir til að meta
        hljóðkerfis- og málvitund barna í elsta árgangi leikskólans. Skimuninni
        er ætlað að finna þau börn sem hafa áhættu fyrir lestrarerfiðleikum en
        slök hljóðkerfis- og málvitund er talin aðalorsök lestrarvanda hjá 88%
        barna (eða 9 af 10 börnum). Á síðustu áratugum hefur verið lögð mikil
        áhersla á að börnum sé strax frá unga aldri boðið markvisst upp á leiki
        og verkefni til örvunar hljóðkerfis- og málmeðvitundar þeirra og að þeim
        sem virðast eiga í erfiðleikum sé sinnt sérstaklega. Leikskólaárin eru
        því sérlega mikilvæg fyrir snemmtæka íhlutun.
      </p>
      <div className='downloadDiv'>
        <a href={aIslensku} target='_blank' rel='noopener noreferrer'>
          <Button children='Á íslensku' />
        </a>
        <a href={aEnsku} target='_blank' rel='noopener noreferrer'>
          <Button children='Á ensku' />
        </a>
        <a href={aPolsku} target='_blank' rel='noopener noreferrer'>
          <Button children='Á pólsku' />
        </a>
      </div>

      <h3>Hugmyndabanki</h3>
      <div className='downloadDiv'>
        <a href={inngangur} target='_blank' rel='noopener noreferrer'>
          <Button children='Inngangur' />
        </a>
        <a href={adgerdaraaetlun} target='_blank' rel='noopener noreferrer'>
          <Button children='Aðgerðaráætlun' />
        </a>
        <a href={rim} target='_blank' rel='noopener noreferrer'>
          <Button children='Rím' />
        </a>
        <a href={samstofur} target='_blank' rel='noopener noreferrer'>
          <Button children='Samstöfur' />
        </a>
        <a href={samsett} target='_blank' rel='noopener noreferrer'>
          <Button children='Samsett orð' />
        </a>
        <a href={hljodgreining} target='_blank' rel='noopener noreferrer'>
          <Button children='Hljóðgreining' />
        </a>
        <a href={margraed} target='_blank' rel='noopener noreferrer'>
          <Button children='Margræð orð' />
        </a>
        <a href={ordhlutaeyding} target='_blank' rel='noopener noreferrer'>
          <Button children='Orðhlutaeyðing' />
        </a>
        <a href={hljodtenging} target='_blank' rel='noopener noreferrer'>
          <Button children='Hljóðtenging' />
        </a>
      </div>
      <h3>Hvernig á að nýta niðurstöður</h3>
      <div className='videoDiv'>
        <iframe
          src='https://player.vimeo.com/video/325444630?h=4a76bf7e22'
          width='560'
          height='315'
          frameborder='0'
          allow='autoplay; fullscreen; picture-in-picture'
          allowfullscreen></iframe>
      </div>

      <br />
      <h3>HLJÓM-2 og persónuvernd</h3>
      <a
        target='_blank'
        rel='noopener noreferrer'
        href='https://www.althingi.is/altext/151/s/1722.html'>
        Lög um breytingu á ýmsum lögum vegna laga um persónuvernd og vinnslu
        persónuupplýsinga, nr. 90/2018 (mennta- og menningarmál).
      </a>
      <p>
        Samkvæmt lögum sem snúa að persónuvernd og menntamálum, sem tóku gildi
        22/06/2021, þá er Leikskólum heimilt að afla og miðla persónuupplýsingum
        til stofnana og fagaðila sem veita börnum lögbundna þjónustu. Leikskólum
        og stofnunum er heimilt að vinna með viðkvæmar persónupplýsingar sem
        snúa að skimunum. Leikskólum ber að upplýsa (skriflegt samþykki ekki
        nauðsynlegt) foreldra og forsjáraðila um vinnsluna.
      </p>
      <p>
        Einnig er leikskólakennurum bent á að skoða{' '}
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://mms.is/personuverndarstefna'>
          persónnuverndarstefnu Menntamálastofnunar
        </a>
        .
      </p>
      <h3>Forspárgildi fyrir lestrarnám</h3>
      <p>
        Prófið skimar fyrir slakri hljóðkerfisvitund en rannsóknir sýna að börn
        með slaka hljóðkerfisvitund hafa auknar líkur á að glíma við náms- og
        lestrarörðugleika þegar komið er í grunnskóla. Fjölmargar rannsóknir
        sýna að hægt er að þjálfa hljóðkerfisvitund barna á leikskólaaldri og ef
        slíkt er gert gengur börnunum betur síðar að læra að lesa. Þjálfun felur
        í sér að verið er að undirbúa lestarnámið hjá börnum með því að kenna
        þeim að hlusta meðvitað eftir hljóðum í orðum og setningum í töluðu máli
        og leika sér með þau.
      </p>
      <p>
        Í lestrarprófum í 1. bekk hafa þættirnir rím, samsett orð og
        hljóðgreining sterkasta fylgni við frammistöðu en í lestrarprófum í 2.
        bekk grunnskóla hafa samsett orð, rím og margræð orð sterkustu fylgnina
        við frammistöðu. Þau börn sem eru í lægstu 25% í HLJÓM-2 á leikskóla eru
        þau sem eiga erfiðast með að læra að lesa. Sama mynstur kemur í ljós ef
        árangur bestu 25% og næst bestu 25% eru skoðuð en það eru einmitt þeir
        nemendur sem standa sig best í lestri við lok fyrsta og annars bekkjar.
      </p>
      <br />
      <h3>Almennar reglur um fyrirlögn</h3>
      <p>
        Foreldrar þurfa að vera upplýstir um skimunina og vinnslu
        Menntamálastofnunar áður en HLJÓM-2 er lagt fyrir barn. Hafa ber í huga
        að allir foreldrar eiga rétt á að fá upplýsingar um stöðu barn síns hver
        sem niðurstaðan hefur orðið. Þó ber alltaf að láta foreldra vita að
        niðurstaðan er aðeins vísbending en ekki endanleg niðurstaða. Ráðgjöf
        til foreldra og samvinna við þá er mjög mikilvæg.
      </p>
      <p>
        Yfirleitt tekur fyrirlögnin um 20 mínútur og er nauðsynlegt að gera ráð
        fyrir nægum tíma. Varast ber að dvelja of lengi við hvert atriði og bíða
        lengi eftir svari barnsins. Ef barnið hefur ekki svarað innan 10-15
        sekúndna á prófandinn að gefa rangt fyrir og fara í næsta atriði. Ef
        barnið sýnir merki um einbeitingar- eða áhugaleysi er gott að taka stutt
        hlé og leyfa því að hreyfa sig.
      </p>
      <p>
        Best er að leggja verkefnin fyrir í einu lagi eða með einu stuttu hléi.
        Ef ekki tekst að leggja prófið fyrir á einum degi er brýnt að ljúka
        fyrirlögn næsta dag. Ef fyrirlögn er ekki lokið innan viku eru
        niðurstöður ómarktækar. Hægt er að leggja prófið fyrir að nýju eftir
        u.þ.b. þrjár vikur.
      </p>
      <br />
      <h3>Frekari athugun</h3>
      <p>
        Ef niðurstöður eru almennt slakar og barn lendir innan rauða svæðisins á
        heildarniðurstöðu er nauðsynlegt að senda barnið í frekari greiningu og
        fá þá m.a. frekara mat t.d. hjá talmeinafræðingi, sálfræðingi og/eða
        barnalækni. Þetta á við um börn þar sem merkt er við mjög slaka færni á
        öllum eða flestum þáttum á skráningarblaðinu.
      </p>
      <p>
        Það má gera ráð fyrir að þetta séu að meðaltali um 10% barnanna en getur
        verið breytilegt milli einstakra leikskóla. Sama getur gilt um börn á
        gráa svæðinu, einkum ef þau eru slök í þeim þáttum sem hafa mest tengsl
        við lestrarerfiðleika. Oftar en ekki er barn með slaka hljóðkerfis- og
        málvitund jafnframt með frávik í almennum málþroska og hugsanlega
        þroskafrávik á öðrum sviðum. Börn sem eru innan rauða og gráa svæðisins
        þurfa sérstaka örvun í leikskóla.
      </p>
      <div className='buttonDiv'>
        <Button
          className='tilBaka'
          children='Til baka'
          onClick={() => history.goBack()}
        />
      </div>
    </div>
  );
}
