/*
Functional component sem fyllir út í skjá með hálfgegnsæum lit.
Hægt að nota undir modalglugga eða svipað
Tekur við callback falli clickCB sem lætur foreldri vita þegar 
klikkað er á það.
*/

import React from 'react';

// Sass
import './Backdrop.scss';

export default function Backdrop(props) {
  const allertParent = () => {
    props.clickCB();
  };

  return <div className='backdrop' onClick={allertParent}></div>;
}
