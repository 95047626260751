import React, { useState, useEffect } from 'react';

// Sass
import './ResultsEinstaklingsTafla.scss';

// Components
import FyrriSeinni from '../fyrriSeinni/FyrriSeinni.jsx';

// Assets
import { lita } from '../../assets/js/functions';

export default function ResultsEinstaklingsTafla(props) {
  const { nemNum, resultsEinstaklingsTaflaCB } = props;
  const [nemandi, setNemandi] = useState([]);
  const [kennitala, setKennitala] = useState('');
  const [fyrri, setFyrri] = useState(false);
  const [seinni, setSeinni] = useState(false);

  useEffect(() => {
    const nemLS = localStorage.getItem(`nemandi${nemNum}`).split(',');
    setNemandi(nemLS);
    setKennitala(nemLS[2]);
  }, [nemNum]);

  const fyrriSeinniCB = (who, values) => {
    if (who === 'fyrri') {
      setFyrri(values);
      resultsEinstaklingsTaflaCB(who, values);
    } else if (who === 'seinni') {
      setSeinni(values);
      resultsEinstaklingsTaflaCB(who, values);
    }
  };

  let fyrriAldursbil;
  let fyrriHeild;
  let fyrriRim;
  let fyrriSamstofur;
  let fyrriSamsett;
  let fyrriHljodgreining;
  let fyrriMargraed;
  let fyrriOrdhlutaeyding;
  let fyrriHljodtenging;
  if (fyrri) {
    fyrriAldursbil = <th>Aldursbil {fyrri[8]}</th>;
    fyrriHeild = <td className={fyrri[16]}>{fyrri[7]}</td>;
    fyrriRim = <td className={fyrri[9]}>{fyrri[0]}</td>;
    fyrriSamstofur = <td className={fyrri[10]}>{fyrri[1]}</td>;
    fyrriSamsett = <td className={fyrri[11]}>{fyrri[2]}</td>;
    fyrriHljodgreining = <td className={fyrri[12]}>{fyrri[3]}</td>;
    fyrriMargraed = <td className={fyrri[13]}>{fyrri[4]}</td>;
    fyrriOrdhlutaeyding = <td className={fyrri[14]}>{fyrri[5]}</td>;
    fyrriHljodtenging = <td className={fyrri[15]}>{fyrri[6]}</td>;
  }

  let seinniAldursbil;
  let seinniHeild;
  let seinniRim;
  let seinniSamstofur;
  let seinniSamsett;
  let seinniHljodgreining;
  let seinniMargraed;
  let seinniOrdhlutaeyding;
  let seinniHljodtenging;
  if (seinni) {
    seinniAldursbil = <th>Aldursbil {seinni[8]}</th>;
    seinniHeild = <td className={seinni[16]}>{seinni[7]}</td>;
    seinniRim = <td className={seinni[9]}>{seinni[0]}</td>;
    seinniSamstofur = <td className={seinni[10]}>{seinni[1]}</td>;
    seinniSamsett = <td className={seinni[11]}>{seinni[2]}</td>;
    seinniHljodgreining = <td className={seinni[12]}>{seinni[3]}</td>;
    seinniMargraed = <td className={seinni[13]}>{seinni[4]}</td>;
    seinniOrdhlutaeyding = <td className={seinni[14]}>{seinni[5]}</td>;
    seinniHljodtenging = <td className={seinni[15]}>{seinni[6]}</td>;
  }

  return (
    <div>
      <div>
        <table className={`einstaklings__tafla${nemNum}`}>
          <thead>
            <tr>
              <th>{nemandi[1]}</th>
              {fyrriAldursbil}
              <th>Aldursbil {nemandi[7]}</th>
              {seinniAldursbil}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Samtals</td>
              {fyrriHeild}
              <td className={lita('heild', parseInt(nemandi[7]), nemandi[15])}>
                {nemandi[15]}
              </td>
              {seinniHeild}
            </tr>
            <tr>
              <td>Rím</td>
              {fyrriRim}
              <td className={lita(1, parseInt(nemandi[7]), nemandi[8])}>
                {nemandi[8]}
              </td>
              {seinniRim}
            </tr>
            <tr>
              <td>Samstöfur</td>
              {fyrriSamstofur}
              <td className={lita(2, parseInt(nemandi[7]), nemandi[9])}>
                {nemandi[9]}
              </td>
              {seinniSamstofur}
            </tr>
            <tr>
              <td>Samsett orð</td>
              {fyrriSamsett}
              <td className={lita(3, parseInt(nemandi[7]), nemandi[10])}>
                {nemandi[10]}
              </td>
              {seinniSamsett}
            </tr>
            <tr>
              <td>Hljóðgreining</td>
              {fyrriHljodgreining}
              <td className={lita(4, parseInt(nemandi[7]), nemandi[11])}>
                {nemandi[11]}
              </td>
              {seinniHljodgreining}
            </tr>
            <tr>
              <td>Margræð orð</td>
              {fyrriMargraed}
              <td className={lita(5, parseInt(nemandi[7]), nemandi[12])}>
                {nemandi[12]}
              </td>
              {seinniMargraed}
            </tr>
            <tr>
              <td>Orðhlutaeyðing</td>
              {fyrriOrdhlutaeyding}
              <td className={lita(6, parseInt(nemandi[7]), nemandi[13])}>
                {nemandi[13]}
              </td>
              {seinniOrdhlutaeyding}
            </tr>
            <tr>
              <td>Hljóðtenging</td>
              {fyrriHljodtenging}
              <td className={lita(7, parseInt(nemandi[7]), nemandi[14])}>
                {nemandi[14]}
              </td>
              {seinniHljodtenging}
            </tr>
          </tbody>
        </table>
      </div>
      <div className='fyrraSeinnaDiv'>
        <div className='fyrraDiv'>
          <FyrriSeinni
            children='Bæta við niðurstöðum úr fyrri athugun'
            fyrriSeinniCB={fyrriSeinniCB}
            who={'fyrri'}
            kennitala={kennitala}
          />
        </div>
        <div className='seinnaDiv'>
          <FyrriSeinni
            children='Bæta við niðurstöðum úr seinni athugun'
            fyrriSeinniCB={fyrriSeinniCB}
            who={'seinni'}
            kennitala={kennitala}
          />
        </div>
      </div>
    </div>
  );
}
