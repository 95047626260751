import React, { Component } from 'react';

// Components
import Button from '../button';

// CSS
import './Kennari.scss';

// Assets
import { validateKennitala } from '../../assets/js/functions';

class Kennari extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kennari: null,
      kennitala: null,
      leikskóli: null,
      sveitarfelag: null,
      errorText: [null, null, null, null],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const val = e.target.value;
    const id = e.target.id;
    const errorText = this.state.errorText;
    if (id === 'kennari__nafn') {
      if (val.length <= 64) {
        errorText[0] = null;
        this.setState({ kennari: val, errorText });
      } else {
        errorText[0] = 'Nafn kennara má ekki vera lengra en 64 stafir';
        this.setState({ errorText });
      }
    } else if (id === 'kennari__kt') {
      if (val.split('-').length === 2) {
        e.target.value = val.split('-')[0] + val.split('-')[1];
      } else if (val.split(' ').length === 2) {
        e.target.value = val.split(' ')[0] + val.split(' ')[1];
      }
      if (val.length === 10) {
        if (validateKennitala(val) === true) {
          errorText[1] = null;
          this.setState({
            kennitala: val,
            message: true,
            error: false,
            errorText,
          });
        } else {
          this.setState({ error: 'Ekki gild kennitala', message: false });
        }
      } else if (val.length < 10) {
        this.setState({ error: false, message: false });
      } else if (val > 10) {
        errorText[1] = 'Kennitala má ekki vera lengri en 10 stafir';
        this.setState({ errorText });
      }
    } else if (id === 'leikskoli') {
      if (val.length <= 64) {
        errorText[2] = null;
        this.setState({ leikskóli: val, errorText });
      } else {
        errorText[2] = 'Nafn leikskóla verður að vera styttra en 64 stafir';
        this.setState({ errorText });
      }
    } else if (id === 'sveitarfelag') {
      if (val.length <= 64) {
        errorText[3] = null;
        this.setState({ sveitarfelag: val });
      } else {
        errorText[3] = 'Nafn sveitarfélags verður að vera styttra en 64 stafir';
        this.setState({ errorText });
      }
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { kennari, kennitala, leikskóli, sveitarfelag } = this.state;

    if (kennari && kennitala && leikskóli && sveitarfelag) {
      this.props.kennaraCB(kennari, kennitala, leikskóli, sveitarfelag);
    }
  }

  render() {
    const { error, message, errorText } = this.state;

    let p;
    if (message) {
      p = <p className='correct'>Gild kennitala</p>;
    }

    const errorElement = errorText.map((err, i) => {
      return (
        <p className='incorrect' key={i}>
          {err}
        </p>
      );
    });
    return (
      <div className='kennari' data-testid='kennari'>
        <h2>Upplýsingar um kennara og leikskóla</h2>
        <form>
          {errorElement}
          <label>Nafn kennara sem leggur prófið fyrir:</label>
          <input
            type='text'
            id='kennari__nafn'
            onChange={this.handleChange}
            placeholder='Nafn'
            data-testid='kennari__nafn'
            maxLength='64'></input>
          <div>
            <label>Kennitala kennara sem leggur prófið fyrir:</label>
            <p className='incorrect'>{error}</p>
            {p}
          </div>
          <input
            type='text'
            id='kennari__kt'
            maxLength='10'
            onChange={this.handleChange}
            placeholder='Kennitala'
            data-testid='kennara__kt'></input>
          <label>Leikskóli:</label>
          <input
            type='text'
            id='leikskoli'
            onChange={this.handleChange}
            placeholder='Leikskóli'
            data-testid='leikskoli'
            maxLength='64'></input>
          <label>Sveitarfélag:</label>
          <input
            type='text'
            id='sveitarfelag'
            onChange={this.handleChange}
            placeholder='Sveitarfélag'
            data-testid='sveitarfelag'
            maxLength='64'></input>
          <Button
            onClick={this.handleSubmit}
            children='ok'
            dataTestid='submit-kennari'
          />
        </form>
      </div>
    );
  }
}

export default Kennari;
