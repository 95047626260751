import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';

// Sass
import './Skra.scss';

// Component
import Button from '../../components/button';
import SkraForm from '../../components/skraForm/SkraForm';

class Skra extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fjoldi: parseInt(localStorage.getItem('fjoldiIProfi')),
      fjoldaArray: [],
      doneNem: parseInt(localStorage.getItem('doneNem')),
    };

    this.formSubmitCB = this.formSubmitCB.bind(this);
    this.makeFjoldaArray = this.makeFjoldaArray.bind(this);
  }

  async componentDidMount() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({ loggedIn: true });
        this.makeFjoldaArray();
      } else {
        this.props.history.push('/login');
      }
    });
    if (
      !localStorage.getItem('fjoldiIProfi') ||
      !localStorage.getItem('kennari')
    ) {
      this.props.history.push('/');
    }
  }

  makeFjoldaArray() {
    const fjoldi = this.state.fjoldi;
    const fjoldaArray = [];
    for (let i = 1; i <= fjoldi; i++) {
      fjoldaArray[i - 1] = i;
    }
    this.setState({ fjoldaArray });
  }

  async formSubmitCB(n, gogn, updateDone) {
    const { doneNem } = this.state;

    // Setja gögn frá formi í LocalStorage
    localStorage.setItem('nemandi' + n, gogn);

    // Uppfæra doneNem Í LocalStorage
    if (updateDone) {
      localStorage.setItem('doneNem', parseInt(doneNem) + 1);
      this.setState({
        doneNem: parseInt(doneNem) + 1,
      });
    }
  }

  render() {
    const { doneNem, fjoldi, fjoldaArray } = this.state;

    let content;
    if (fjoldi) {
      content = fjoldaArray.map((i) => {
        const nem = localStorage.getItem('nemandi' + i);
        return (
          <div
            className='col col-6 offset-col-3 col-md-11 offset-col-md-0'
            key={i}>
            <SkraForm
              num={i}
              formSubmitCB={this.formSubmitCB}
              nem={nem}></SkraForm>
          </div>
        );
      });
    }

    let takki;
    if (parseInt(doneNem) !== parseInt(fjoldi) && fjoldi) {
      takki = (
        <div
          className='col col-2 offset-col-5 heildarSub col-md-6 offset-col-md-3'
          key='sub'>
          <Button
            className='button__inactive'
            children='Skoða tölfræði og heildarniðurstöður'></Button>
        </div>
      );
    } else if (parseInt(doneNem) === parseInt(fjoldi) && fjoldi) {
      takki = (
        <div
          className='col col-2 offset-col-5 heildarSub col-md-6 offset-col-md-3'
          key='sub'>
          <Link to='/results'>
            <Button children='Skoða tölfræði og heildarniðurstöður'></Button>
          </Link>
        </div>
      );
    }

    return (
      <div className='skra row' data-testid='skra'>
        <h2 className='col col-6 offset-col-3 col-md-10 offset-col-md-1'>
          Skráningarform fyrir hvern nemanda
        </h2>
        {content}
        {takki}
      </div>
    );
  }
}
export default Skra;
