import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Button.scss';

/* todo aðrar útgáfur af takka fyrir disabled og "warning" takka */

export default class Button extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
    to: PropTypes.node,
  };

  static defaultProps = {
    className: '',
    onClick: () => {},
  };

  render() {
    const { children, className, onClick, id } = this.props;
    const classes = `button ${className}`;

    return (
      <button
        onClick={onClick}
        className={classes}
        id={id}
        data-testid={this.props.dataTestid}>
        {children}
      </button>
    );
  }
}
